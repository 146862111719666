"use client";

import { useQuery } from "@tanstack/react-query";
import dynamic from "next/dynamic";
import { fetchWeatherLocations } from "../actions";

const FooterWeatherUI = dynamic(
  () => import("./FooterWeatherUI").then((mod) => mod.FooterWeatherUI),
  { ssr: false },
);

export const FooterWeather = () => {
  const { data: windspeed } = useQuery({
    queryKey: ["weather-data"],
    queryFn: () => fetchWeatherLocations(),
  });

  if (!windspeed) return null;

  return <FooterWeatherUI windspeed={windspeed} />;
};
