"use client";
import CountryDropdown from "@/features/checkout/CountryDropdown";
import useInput from "@/features/checkout/useInput";
import useModal from "@/hooks/useModal";
import { useTranslations } from "next-intl";
import { usePathname } from "next/navigation";
import Button from "../Button";
import Modal from "../Modal";
import styles from "./index.module.css";

const LocationModal = () => {
  const country = useInput("text");
  const pathname = usePathname();
  const t = useTranslations();
  // const pathname = usePathname();
  // const router = useRouter();
  // const options: DropdownOption[] = [];
  // const currentLocale = useLocale();
  const { closeModal } = useModal("location", { popover: true });

  const handleClose = () => {
    if (pathname === "/checkout") {
      closeModal();
      window.location.reload();
      return;
    }
    closeModal();
  };

  return (
    <Modal
      modal={"location"}
      className={styles.modal}
      title={t("footer.location.title")}
      popover
    >
      <div className={styles.modal__content}>
        <div className={styles.section}>
          <span className={styles.label}>{t("footer.location.country")}</span>
          <CountryDropdown {...country} />
        </div>

        {/* ---  For future development --- */}

        {/* <div className={styles.section}>
          <span className={styles.label}>{t("footer.location.language")}</span>
          <Dropdown
            options={options}
            onChange={(val) => {
              const locale = val?.value;
              router.push(pathname, { locale });
            }}
            value={currentLocale}
          />
        </div> */}
        <Button onClick={handleClose} color="black" full>
          {t("common.saveSettings")}
        </Button>
      </div>
    </Modal>
  );
};

export default LocationModal;
