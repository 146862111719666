"use client";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import formatSelection from "@/lib/centra/formatters/formatSelection";
import useLocation from "@/lib/centra/hooks/useLocation";
import usePersistedStore from "@/lib/stateManagement/persistedState/persistedStore";

import type { UseSelectionReturn } from "../useSelection/useSelection";
export const optimisticUpdateLocation = (
  cart: UseSelectionReturn,
  variables: {
    locationId: string;
  },
) => {
  const currentCountry = cart.countries.find(
    (c) => c?.country === variables.locationId,
  );
  const newLocation = {
    country: currentCountry?.country || "",
    name: currentCountry?.name || "",
    eu: currentCountry?.eu || false,
  };
  return {
    ...cart,
    location: { ...cart.location, ...newLocation },
    selection: {
      ...cart.selection,
      currency: cart.countries.find((c) => c?.country === variables.locationId)
        ?.currency,
    },
  };
};
export const setLocation = async (
  variables: {
    locationId: string;
    stateId?: string;
  },
  customerToken: string,
  current?: any,
) => {
  if (window.CentraCheckout) window.CentraCheckout.suspend();
  const { locationId, stateId } = variables;
  const api = process.env.NEXT_PUBLIC_CENTRA_CHECKOUT_API;
  const url = stateId
    ? `${api}/countries/${locationId}/states/${stateId}`
    : `${api}/countries/${locationId}`;
  const res = await fetch(url, {
    method: "PUT",
    headers: { "API-token": customerToken || "" },
  });
  if (!res.ok) throw Error("Error changing location");
  const data = await res.json();
  return data;
};

const useChangeLocation = () => {
  const queryClient = useQueryClient();
  const customerToken = usePersistedStore((state) => state.token);
  const setToken = usePersistedStore((state) => state.setToken);
  const { states, state, currentCountry } = useLocation();
  const changeLocation = useMutation({
    mutationFn: async (variables: { locationId: string; stateId?: string }) => {
      console.log("VARIABLES", variables);
      await queryClient.cancelQueries({ queryKey: ["selection"] });
      return await setLocation(variables, customerToken, {
        states,
        state,
        currentCountry,
      });
    },

    onMutate: async (variables: { locationId: string }) => {
      // Snapshot the previous value
      const oldData = queryClient.getQueryData(["selection"]);

      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      //eslint-disable-next-line

      // // Optimistically update to the new value
      await queryClient.setQueryData(["selection"], (oldCart: any) => {
        return optimisticUpdateLocation(oldCart, variables);
      });
      return { oldData };
    },

    onSuccess: async (data: any) => {
      //eslint-disable-next-line
      //
      setToken(data?.token);
      return await queryClient.setQueryData(
        ["selection"],
        formatSelection(data),
      );
    },

    onSettled: async (
      data: any,
      error: any,
      variables: { locationId: string },
      context: any,
    ) => {
      //eslint-disable-next-line
      //
      setToken(data?.token || context?.oldData?.token);
      return await queryClient.invalidateQueries({ queryKey: ["selection"] });
    },
  });

  return { changeLocation };
};

export default useChangeLocation;
